<template>
    <div class="feedback">
        <div class="auto-1440">
            <Breadcrumb type="grey"/>
            <div class="title">
                <h1>问题处理中心反馈平台</h1>
                <h2>如果您在使用网站的过程中出现问题，请留言反馈。我们会尽快处理并及时回复您，谢谢！</h2>
            </div>
            <div class="form">
                <a-form-model 
                    :model="form"
                    ref="ruleForm"
                    :rules="rules"
                >
                    <div class="form-item w2">
                        <a-form-model-item label="账号" >
                            <a-input v-model="accont" disabled />
                        </a-form-model-item>
                        <a-form-model-item label="问题分类" prop="Type">
                            <a-select 
                                size="large"
                                placeholder="问题分类"
                                v-model="form.Type"
                            >
                                <a-icon slot="suffixIcon" type="caret-down" />
                                <a-select-option v-for="option in options" :key="option.value" :value="option.value">
                                    {{option.title}}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </div>
                    <a-form-model-item label="问题描述" prop="Info">
                        <div class="input">
                            <a-textarea
                                v-model="form.Info"
                                :maxLength="3000"
                                show-count
                                :auto-size="{ minRows: 6, maxRows: 8 }"
                            />
                            <div class="limit">{{form.Info.length}}/3000</div>
                        </div>
                        
                    </a-form-model-item>
                    <a-form-model-item label="请上传遇到问题的截图" >
                        <!-- <div class="img_loading" v-show="imgLoading">
                            <a-icon type="loading" />
                            <span>上传中</span>
                        </div> -->
                        <a-upload-dragger
                            name="file"
                            :multiple="true"
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            @change="handleChange"
                            :customRequest="customRequest"
                            :before-upload="beforeUpload"
                            @preview="handlePreview"
                            :fileList=[]
                        >
                           
                            <div class="des">
                                <h2>请选择您需要上传的图片或拖拽文件至此</h2>
                                <p>图片类型仅支持jpg、png<br>大小不要超过3MB/张</p>
                            </div>
                        </a-upload-dragger>
                        <div class="img_list">

                        </div>
                    </a-form-model-item>
                    <div class="img-list" v-viewer.rebuild>
                        <template v-for="(decImg, index) in imgList">
                            <div class="img" :key="index" >
                                <img v-if="decImg"  :src="decImg.url" :ref="'viewer' + decImg.uid" />
                                <div class="handle">
                                    <a-icon type="eye" @click.stop="show(decImg.uid)" title="预览"/>
                                    <a-icon type="delete" title="删除" @click.stop="deleteItem(decImg.uid)" />
                                </div>
                            </div>
                        </template>
                        <transition name="img" mode="out-in">
                            <div class="img uploading" v-if="imgLoading">
                                <a-icon type="loading" />
                                <span>上传中</span>
                            </div>
                        </transition>
                    </div>
                </a-form-model>
                <a-form-model-item >
                    <div class="submit">
                        <a-button :loading="loading"  @click="submit">提    交</a-button>
                    </div>
                </a-form-model-item>
            </div>
        </div>
        <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>
    </div>
</template>
<script>
import Breadcrumb from '~/components/common/Breadcrumb'
import { mapState, mapActions, mapMutations } from 'vuex'
import { Base64 } from 'js-base64'
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
import { GetOptionList , getOSSUpload  , SaveQuestion} from '@/api/common'
import axios from 'axios'
export default {
    metaInfo() {
		return {
			title: this.$route.meta.title|| '文物出版社',
			meta: [
                {
                    name: 'keyWords',
					content: this.$route.meta.title|| '文物出版社'
                },
                {
                    name: 'description',
					content: this.$route.meta.title|| '文物出版社'
                }
            ]
		}
	},
    components:{
        Breadcrumb
    },
    data(){
        return{
            form:{
                MemberId:'',
                Type:undefined,
                Info:'',
                Image:'',
            },
            accont:'',
            options:[],
            rules:{
                Type: [
                    { required: true, message: '请选择问题分类', trigger: 'change' }
                ],
                Info: [
                    { required: true, message: '请输入您遇到的问题', trigger: 'blur' }
                ],
            },
            action:window.configObj.ossURL,
            imgList:[],
            imgLoading:false,
            loading:false,
            previewVisible:false,
            previewImage:'',
        }
    },
    computed:{
        ...mapState({
            userInfo: (state) => state.user.userInfo,
        }),
    },
    watch:{
        userInfo:{
            handler(newVal,oldVal){
                this.form.MemberId = newVal.Id
                this.accont = newVal.LoginName
            },
            immediate:true
        },
        imgList(val){
            const str = val.map(item => item.url).join('*')
            this.form.Image = str
            console.log(str);
        }
    },
    methods:{
        beforeUpload(file){
            const isLt2M = file.size / 1024 / 1024 < 3;
            if (!isLt2M) {
                this.$message.error('请上传小于2M的图片！');
            }
            return isLt2M;
        },
        handleChange(info) {
            const status = info.file.status;
            if(status == 'removed'){
                this.imgList = this.imgList.filter(item => item.uid != info.file.uid)
            }
        },
        submit(){
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    if(this.imgLoading) return 
                    this.loading = true
                    SaveQuestion(Base64.encode(JSON.stringify(this.form))).then(res => {
                        console.log(res);
                        this.$message.success(res.msg)
                        this.imgList = [],
                        this.$refs.ruleForm.resetFields()
                        this.loading = false
                    }).catch(err => {
                        this.loading = false
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            })
        },
        async getOption(){
            const { data } = await GetOptionList({fieldId:7405})
            this.options = data
        },
        async customRequest(file){
            this.imgLoading = true
            const fileObj = file.file // 相当于input里取得的files
            const fd = new FormData() // FormData 对象
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            await getOSSUpload({ fileName: fileObj.name }).then(res => {
                if (res.code === 200) { 
                    fd.append('key', res.data.key)
                    fd.append('policy', res.data.policy)
                    fd.append('OSSAccessKeyId', res.data.accessid)
                    fd.append('success_action_status', '200') // 让服务端返回200,不然，默认会返回204
                    fd.append('callback', res.data.callback)
                    fd.append('signature', res.data.signature)
                    fd.append('file', fileObj) // 文件对象
                    axios.post(this.action, fd, config).then((res) => {
                        console.log(res);
                        this.imgList.push(
                            {
                                uid: res.data.data.src,
                                status: 'done',
                                name: res.data.data.title,
                                url: res.data.data.src
                            }
                        )
                        this.imgLoading = false
                    }).catch(err => {
                        console.log(err);
                        this.imgLoading = false
                    })
                }
            }).catch(err => {
                console.log(err);
            })
            
        },
        async handlePreview(file){
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }
            this.previewImage = file.url || file.preview;
            this.previewVisible = true;
        },
        handleCancel() {
            this.previewVisible = false;
        },
        show(uid){
            console.log(this.$refs['viewer' + uid]);
            this.$refs['viewer' + uid][0].click()
            return
        },
        deleteItem(uid){
            this.imgList = this.imgList.filter(item => item.uid !== uid)
        }
    },
    mounted(){
        this.getOption()
    }
}
</script>
<style scoped lang="less">
/deep/.breadcrumb{
    border-bottom: 0;
}
.feedback{
    padding-bottom: 110px;
}
.title{
    text-align: center;
    padding-top: 20px;
    padding-bottom: 35px;
    h1{
        font-size: 32px; 
        color: rgb(51, 51, 51);
        padding-bottom: 15px;
        position: relative;
        margin-bottom: 25px;
        &::after{
            content: '';
            width: 40px;
            height: 5px;
            border-radius: 3px;
            background-color: #00ffc0;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
        }
    }
    h2{
        font-size: 18px;
        color: rgb(85, 85, 85);
        line-height: 1.667;
    }
}
.form{
    border-radius: 20px;
    background-color: rgb(245, 245, 245);
    padding: 60px 100px 50px;
    /deep/.ant-form-item{
        margin-bottom: 30px;
    }
    /deep/.ant-form-item-with-help{
        margin-bottom: 7px;
    }
    /deep/.ant-form-item-label{
        font-size: 20px;
        color: rgb(51, 51, 51);
        line-height: 1;
        margin-bottom: 20px;
        label{
            font-size: 20px;
            color: rgb(51, 51, 51);
            display: flex;
            flex-direction: row-reverse;
            &::after{
                display: none;
            }
        }
    }
    /deep/.ant-input{
        height: 60px;
        line-height: 60px;
        border-radius: 3px;
        padding: 0 20px;
        font-size: 18px;
    }
    /deep/.ant-input-disabled{
        background-color: #eee;
        border-color: #eee !important;
    }

}
.form-item.w2{
    display: flex;
    justify-content: space-between;
    .ant-form-item{
        width: calc(50% - 40px);
    }
}
textarea.ant-input{
    line-height: 2 !important;
    padding: 20px;
}
/deep/.ant-select-selection{
    background-color: #fff;
    line-height: 60px;
    height: 60px !important;
}
/deep/.ant-select-selection__rendered{
    line-height: 58px !important;
}
/deep/.ant-upload{
    border-color: transparent !important;
    background-color: #eeeeee !important;
    &:hover{
        border-color: #00ffc0 !important;
    }
}
/deep/.ant-upload-btn{
    padding: 80px 30px 70px !important;
    h2{
        font-size: 24px;
        color: rgb(136, 136, 136);
        line-height: 1.25;
        margin-bottom: 8px;
    }
    .des{
        font-size: 18px;
        color: rgb(51, 51, 51);
        line-height: 1.667;
    }
}

.submit{
    text-align: center;
    .ant-btn{
        min-width: 400px;
        padding: 0 40px;
        height: 60px;
        line-height: 60px;
        border-radius: 30px;
        background-color: #00ffc0;
        color: #000;
        font-size: 24px;
        border-color: #00ffc0;
        &:hover{
            background-color: #79f9d9;
            border-color: #79f9d9;
            box-shadow: 0 0 0.1rem 0 rgba(121, 249, 217, 0.8);
        }
    }
}
.has-error{
    .ant-select-selection:hover{box-shadow: none;}

}
/deep/.ant-form-item-children{
    position: relative;
}
.img_loading{
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: rgba(0,0,0,.8);
    z-index: 2;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: #fff;
}
.img-list{
    display: flex;
    margin-bottom: 30px;
    .img{
        width: 120px;
        height: 120px;
        border: 1px solid #d9d9d9;
        padding: 8px;
        border-radius: 2px;
        position: relative;
        cursor: pointer;
        margin-right: 10px;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            display: block;
        }
        .handle{
            position: absolute;
            width: 102px;
            height: 102px;
            left: 8px;
            top: 8px;
            background-color: #00000080;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            color: #fff;
            opacity: 0;
            transition: .3s;
            &:hover{
                opacity: 1;
            }
            .anticon{
                opacity: 0.8;
                margin: 0 5px;
                &:hover{
                    opacity: 1;
                }
            }
        }
        
    }
    .uploading{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        span{
            margin-top: 10px;
        }
    }
}
.input{
    position: relative;
    .limit{
        position: absolute;
        right: 10px;
        bottom: 0px;
        color: #999;
    }
    .ant-input{
        padding-bottom: 30px;
    }
}
/* fade-transform */
.img-transform-leave-active,
.img-transform-enter-active {
  transition: all .5s;
}

.img-transform-enter {
  opacity: 0;
//   transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
//   transform: translateX(30px);
}
</style>